@import "./variables.scss";

@font-face {
  font-family: "CurlyBoys";
  src: url("../assets/fonts/rahayu/Rahayu\ Script.ttf") format("truetype");
}

@font-face {
  font-family: "GothamBold";
  src: url("../assets/fonts/gotham-bold/gothambold1.ttf") format("truetype");
}

* {
  font-family: "GothamBold" !important;
  color: white;
}

body {
  height: 100%;
  background: $background-color;
}

#root {
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.name {
  font-size: 18rem;
}

.app-container .content {
  height: 100%;
  width: 70%;
  margin: 10px solid black;
  margin: 0 auto;
}

.page {
  background-color: lighten($color: $background-color, $amount: 10);
  padding: 1.5rem 2rem;
  border: 1px solid grey;
}
