@import "../../styles/variables.scss";

nav {
  font-size: 3rem;
  background: $nav-color;

  min-height: 100%;
  padding: 0 2.5%;
  padding-top: 2.5%;

  ul,
  li,
  a {
    text-decoration: none;
    list-style-type: none;
    overflow: hidden;
    color: $nav-text-color;
    transition: color 0.15s;

    &:hover {
      color: white;
    }
  }

  .logo {
    transition: all 0.3s cubic-bezier(0.36, -0.64, 0.34, 1.76);
    transform: rotate(0deg);
  }

  .children {
    height: auto;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.36, -0.64, 0.34, 1.76);
  }

  &.closed {
    .logo {
      transform: rotate(90deg);
    }

    .children {
      height: 0;
      opacity: 0;
      width: 0;
    }
  }

  & .nav-group-container {
    margin-bottom: 0;
    padding-bottom: 0;

    .nav-group {
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
      opacity: 0;
      transform: rotateX(-90deg);
      transition: all 0.4s cubic-bezier(0.36, -0.64, 0.34, 1.76);

      &.open {
        height: auto;
        opacity: 1;
        transform: none;
      }
    }
  }
}
