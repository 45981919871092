@import "../../styles/variables.scss";

.forget,
.remember {
  background-color: lighten($color: $background-color, $amount: 0.5);
  border: 3px $nav-color;
  width: 100%;

  div {
    background-color: lighten($color: $background-color, $amount: 10);
    width: 100%;
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0;
    padding: 0.5rem 0;

    textarea {
      margin: 0;
      width: 95%;
      resize: none;
      min-height: 300px;
      color: $text-secondary;
      background-color: $background-color;
      border: none;
    }
    button {
      margin-top: 1rem;
      color: $nav-text-color;
    }
  }
}
