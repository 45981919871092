@import "../../styles/variables.scss";
div {
  p {
    color: $text-secondary;
  }

  ul.tools {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      padding: 0.5rem 1rem;
      background-color: $background-color;
      border: 1px solid darken($text-secondary, 50);

      a {
        text-decoration: none;
      }
    }
  }
}
